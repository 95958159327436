/*
* Width
*/
.w-25 {
  width: 25%!important;
}
.w-50{
  width: 50%!important;
}
.w-75 {
  width: 75%!important;
}
.w-100 {
  width: 100%!important;
}
.w-1-3 {
  width: calc(100% / 3)!important;
}
.w-2-3 {
  width: calc(2 * calc(100% / 3))!important;
}
.w-fit-content {
  width: fit-content!important;
}

/* md */
.w-md-25 {
  @media screen and (max-width: 1200px) {
    width: 25%!important;
  }
}
.w-md-50{
  @media screen and (max-width: 1200px) {
    width: 50%!important;
  }
}
.w-md-75 {
  @media screen and (max-width: 1200px) {
    width: 75%!important;
  }
}
.w-md-100 {
  @media screen and (max-width: 1200px) {
    width: 100%!important;
  }
}
.w-md-1-3 {
  @media screen and (max-width: 1200px) {
    width: calc(100% / 3)!important;
  }
}
.w-md-2-3 {
  @media screen and (max-width: 1200px) {
    width: calc(2 * calc(100% / 3))!important;
  }
}
.w-md-fit-content {
  @media screen and (max-width: 1200px) {
    width: fit-content!important;
  }
}

/* sm */
.w-sm-25 {
  @media screen and (max-width: 767px) {
    width: 25%!important;
  }
}
.w-sm-50{
  @media screen and (max-width: 767px) {
    width: 50%!important;
  }
}
.w-sm-75 {
  @media screen and (max-width: 767px) {
    width: 75%!important;
  }
}
.w-sm-100 {
  @media screen and (max-width: 767px) {
    width: 100%!important;
  }
}
.w-sm-1-3 {
  @media screen and (max-width: 767px) {
    width: calc(100% / 3)!important;
  }
}
.w-sm-2-3 {
  @media screen and (max-width: 767px) {
    width: calc(2 * calc(100% / 3))!important;
  }
}

/*
* Padding
*/

//0em
.pv-0 {
    padding-top: 0!important;
    padding-bottom: 0!important ;
}
.ph-0 {
    padding-left: 0!important;
    padding-right: 0!important ;
}
.pr-0 {
    padding-right: 0!important ;
}
.pl-0 {
    padding-left: 0!important;
}
.pt-0 {
    padding-top: 0!important;
}
.pb-0 {
    padding-bottom: 0!important;
}
.pl-0 {
    padding-left: 0!important;
}
.pt-0 {
    padding-top: 0!important;
}
.p-0 {
    padding: 0!important;
}

//1em
.pr-1 {
    padding-right: 1em!important ;
}
.pl-1 {
    padding-left: 1em!important;
}
.pt-1 {
    padding-top: 1em!important;
}
.pb-1 {
    padding-bottom: 1em!important;
}
.pv-1 {
    padding-top: 1em!important;
    padding-bottom: 1em!important ;
}
.ph-1 {
    padding-left: 1em!important;
    padding-right: 1em!important ;
}
.pt-sm-1 {
    @media screen and (max-width: 767px) {
        padding-top: 1em!important;
    }
}
.pb-sm-1 {
    @media screen and (max-width: 767px) {
        padding-bottom: 1em !important;
    }
}

// 2em
.pv {
    padding-top: 2em!important;
    padding-bottom: 2em!important ;
}
.ph {
    padding-left: 2em!important;
    padding-right: 2em!important ;
}
.pr {
    padding-right: 2em!important ;
}
.pl {
    padding-left: 2em!important;
}
.pt {
    padding-top: 2em!important;
}
.pb {
    padding-bottom: 2em!important;
}

.pb {
    padding-bottom: 2em!important;
}

/* md */
.pb-md-1 {
    @media screen and (max-width: 1200px) {
        padding-bottom: 1em!important;
    }
}
.pl-md-0 {
    @media screen and (max-width: 1200px) {
        padding-left: 0!important;
    }
}
.pv-md-0 {
    @media screen and (max-width: 1200px) {
        padding-left: 0!important;
        padding-right: 0!important;
    }
}


/* sm */
.pl-sm-0 {
    @media screen and (max-width: 767px) {
        padding-left: 0!important;
    }
}
.pv-sm-0 {
    @media screen and (max-width: 767px) {
        padding-left: 0!important;
        padding-right: 0!important;
    }
}

/*
* alignments
*/
.justify-center {
  justify-content: center!important;
}
.justify-start {
  justify-content: flex-start!important;
}
.align-items-start {
  align-items: flex-start!important;
}
.align-content-start {
  align-content: flex-start!important;
}
.align-self-start {
  align-self: flex-start;
}
.align-items-center {
  align-items: center!important;
}
.align-content-center {
  align-content: center!important;
}
.align-items-end {
  align-items: flex-end!important;
}

.align-self-end {
  align-self: flex-end!important;
}
.justify-end {
  justify-content: flex-end!important;
}

/*
  md
*/
.justify-md-start {
  @media screen and (max-width: 1200px) {
    justify-content: flex-start!important;
  }
}
.align-self-md-start {
  @media screen and (max-width: 1200px) {
    align-self: flex-start;
  }
}
.align-content-md-start {
  @media screen and (max-width: 1200px) {
    align-content: flex-start!important;
  }
}
.align-items-md-start {
  @media screen and (max-width: 1200px) {
    align-items: flex-start!important;
  }
}
.justify-md-between {
  @media screen and (max-width: 1200px) {
    justify-content: space-between!important;
  }
}
.justify-md-center {
  @media screen and (max-width: 1200px) {
    justify-content: center!important;
  }
}
.align-items-md-center {
  @media screen and (max-width: 1200px) {
    align-items: center!important;
  }
}
.align-content-md-center {
  @media screen and (max-width: 1200px) {
    align-content: center!important;
  }
}
.justify-md-end {
  @media screen and (max-width: 1200px) {
    justify-content: flex-end!important;
  }
}
.align-items-md-end {
  @media screen and (max-width: 1200px) {
    align-items: flex-end!important;
  }
}

/*
  sm
*/
.justify-sm-start {
  @media screen and (max-width: 767px) {
    justify-content: flex-start!important;
  }
}
.align-self-sm-start {
  @media screen and (max-width: 767px) {
    align-self: flex-start;
  }
}
.align-content-sm-start {
  @media screen and (max-width: 767px) {
    align-content: flex-start!important;
  }
}
.align-items-sm-start {
  @media screen and (max-width: 767px) {
    align-items: flex-start!important;
  }
}
.justify-sm-center {
  @media screen and (max-width: 767px) {
    justify-content: center!important;
  }
}
.align-content-sm-center {
   @media screen and (max-width: 767px) {
     align-content: center!important;
   }
 }
.align-items-sm-center {
  @media screen and (max-width: 767px) {
    align-items: center!important;
  }
}
.align-items-sm-end {
  @media screen and (max-width: 767px) {
    align-items: flex-end!important;
  }
}
.justify-sm-end {
  @media screen and (max-width: 767px) {
    justify-content: flex-end!important;
  }
}
